import React, { useState } from "react";
import { useFormik } from "formik";
import { Helmet } from "react-helmet";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import axios from "axios";

import "./style.css";

import {
  companySignupSchema,
  personalSignupSchema,
} from "../validations/signupValidations";
import { Document, FormValues } from "../../shared/interface";
import {
  DOCUMENT_TYPE,
  metaData,
  presignedData,
  title,
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  FILE_PATH,
  FILE_TYPE,
} from "../constants";
import { PROFILE_TYPES } from "../../shared/enum";
import { PersonalFormValues } from "../../shared/interface";
import globe from "../../assests/home/globe.png";
import CompanyDetails from "./CompanyDetails";
import { createUser } from "../../api/user";
import { isSuccess, notify } from "../../utils";
import TechnologyDetails from "./TechnologyDetails";
import OpportunityDetails from "./OpportunityDetails";
import SuccessModal from "./SuccessModal";
import { RequestMethods } from "../../shared/RequestMethods";
import PersonalInformation from "./PersonalInformation";

const Signup = ({ handleLoginModal }: { handleLoginModal: () => void }) => {
  const [profileType, setProfileType] = useState(PROFILE_TYPES.DISPLAYER);
  const [country, setCountry] = useState("IN");
  const [step, setStep] = useState(0);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [state, setState] = useState("LOADING");
  const [message, setMessage] = useState("");

  const Error_modal = document.getElementById("error-modal");

  const [comp_logo, setCompLogo] = useState<FormValues["logo"]>(null);
  const [comp_documents, setCompDocs] = useState<FormValues["documents"]>([]);
  const [comp_documents_start, setCompDocsStart] = useState<
    FormValues["s_documents"]
  >({ document: null, documentType: DOCUMENT_TYPE.OPEN });
  const [uploadLogo, setUploadLogoM] = useState(false);

  const handleProfileTypes = (currentProfileType: PROFILE_TYPES) => {
    setProfileType(currentProfileType);
  };

  const handleCountry = (currentCountry: string) => {
    setCountry(currentCountry);
  };

  const handleSuccessModal = (
    isOpen: boolean,
    state: string,
    message: string
  ) => {
    setSuccessModal(isOpen);
    setState(state);
    setMessage(message);
  };

  const handleGeneralProfile = async (values: PersonalFormValues) => {
    handleSuccessModal(true, "Please Wait", "LOADING");

    const data = {
      userType: profileType,
      fullName: values.name,
      email: values.email,
      countryCode: country,
      phoneNumber: values.phone.toString(),
      referenceCode: values.ref,
      password: values.password,
    };
    const res = await createUser(data);
    console.log({ success: isSuccess(res) });
    if (isSuccess(res)) {
      console.log({ success: isSuccess(res) });
      handleSuccessModal(true, "Registered successfully!", "SUCCESS");
      notify("Registered successfully!", "success");
    } else {
      handleSuccessModal(true, "Registered failed!", "ERROR");
      notify("Registration failed!", "error");
    }
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: RequestMethods.POST,
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
      });

    return result;
  };

  const toggle_error = () => {
    if (Error_modal?.hasAttribute("hidden")) {
      Error_modal?.classList.remove("hidden");
      Error_modal?.classList.add("container");
    } else {
      Error_modal?.classList.remove("container");
      Error_modal?.classList.add("hidden");
    }
  };

  const postDocument = async (signed: string, file: File | null) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };
    if (file) {
      await axios(config)
        .then(function (response) {})
        .catch(function (error) {
          toggle_error();
        });
    }
  };

  const postFile = async (signed: string) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: comp_logo,
    };

    await axios(config)
      .then(function (response) {})
      .catch(function (error) {
        toggle_error();
      });
  };

  const handleCompanySubmit = async (values: FormValues) => {
    let signedLogoURL: string = "";
    let signedDocumentURLWhole: string = "";

    const signedData: presignedData = {
      fileName: values?.logo?.name || values.name,
      filePath: FILE_PATH.COMPANY_LOGO,
      fileType: FILE_TYPE.PNG,
    };
    signedLogoURL = await getPresigned(signedData);
    postFile(signedLogoURL);
    comp_documents.push(comp_documents_start);

    const temp_docs: any[] = [];
    if (comp_documents) {
      Array.from(comp_documents).forEach(async (document: Document, i) => {
        let signedDocumentData: presignedData = {
          fileName: document.document?.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let tempurl = (await getPresigned(signedDocumentData)) + " ";
        temp_docs.push({
          document: tempurl,
          documentType: document.documentType,
        });
        signedDocumentURLWhole = tempurl.split("?")[0] + " ";
        postDocument(tempurl, document.document);
      });
    }

    const data = {
      userType: profileType,
      fullName: personalFormik.values.name,
      email: personalFormik.values.email,
      countryCode: country,
      phoneNumber: personalFormik.values.phone.toString(),
      referenceCode: personalFormik.values.ref,
      password: personalFormik.values.password,
      companyId: null,
      companyDetails: {
        name: values.name,
        logo: signedLogoURL.split("?")[0],
        description: values.description,
        address: values.address,
        website: values.website,
        country: values.country,
        companyTurnover: values.turnover,
      },
      companyDocuments: temp_docs,
    };

    let userId = null;

    const response = await axios.post(
      `${process.env.REACT_APP_BASE_API}/users`,
      data
    );
    const responseData = response?.data?.message;
    userId = responseData?._id;

    return userId;
  };

  const handleUserRegistration = async () => {
    const userId = await handleCompanySubmit({
      name: companyFormik?.values?.name,
      description: companyFormik?.values?.description,
      address: companyFormik?.values?.address,
      website: companyFormik?.values?.website,
      country: companyFormik?.values?.country,
      turnover: companyFormik?.values?.turnover,
      logo: companyFormik?.values?.logo,
      documents: companyFormik?.values?.documents,
      s_documents: companyFormik?.values?.s_documents,
    });

    return userId || "";
  };

  const handleNext = () => {
    setStep((prev) => prev + 1);
  };

  const handlePrev = () => {
    setStep((prev) => prev - 1);
  };

  const personalFormik = useFormik<PersonalFormValues>({
    initialValues: {
      name: "",
      email: "",
      password: "",
      country: "",
      phone: "",
      ref: "",
    },
    validationSchema: personalSignupSchema,
    onSubmit: (values, formikHelpers) => {
      if (profileType === PROFILE_TYPES?.GENERAL_SUBSCRIBER)
        handleGeneralProfile(values);
      else handleNext();
    },
  });

  const companyFormik = useFormik<FormValues>({
    initialValues: {
      name: "",
      description: "",
      address: "",
      website: "",
      country: "",
      turnover: "",
      logo: null,
      documents: [],
      s_documents: { document: null, documentType: DOCUMENT_TYPE.OPEN },
    },
    validationSchema: companySignupSchema,
    onSubmit: (values, formikHelpers) => {
      handleNext();
    },
  });

  const steps = [
    {
      title: "Personal Information",
      component: (
        <PersonalInformation
          profileType={profileType}
          country={country}
          personalFormik={personalFormik}
          handleCountry={handleCountry}
          handleProfileTypes={handleProfileTypes}
        />
      ),
    },
    {
      title: "Company Information",
      component: (
        <CompanyDetails
          companyFormik={companyFormik}
          uploadLogo={uploadLogo}
          toggle_error={toggle_error}
          comp_logo={comp_logo}
          setUploadLogoM={setUploadLogoM}
          setCompLogo={setCompLogo}
        />
      ),
    },
    {
      title: "Product/Opportunity Information",
      component:
        profileType === PROFILE_TYPES?.DISPLAYER ? (
          <TechnologyDetails
            handleTechnologySubmit={handleUserRegistration}
            handleSuccessModal={handleSuccessModal}
          />
        ) : (
          <OpportunityDetails
            handleTechnologySubmit={handleUserRegistration}
            handleSuccessModal={handleSuccessModal}
          />
        ),
    },
  ];

  return (
    <div className="flex flex-col justify-center w-full items-center -z-10">
      <Helmet>
        <title>{title.SIGN_UP}</title>
        <meta name="description" key="description" content={metaData.SIGN_UP} />
        <meta name="title" key="title" content={title.SIGN_UP} />
        <meta property="og:title" content={title.SIGN_UP} />
        <meta property="og:description" content={metaData.SIGN_UP} />
        <meta property="og:image" content={globe} />
        <meta
          property="og:url"
          content={`${process.env.REACT_APP_BASE_URL}/signup`}
        />
        <meta property="og:type" content="website" />
        <meta name="twitter:title" content={title?.SIGN_UP} />
        <meta name="twitter:description" content={metaData?.SIGN_UP} />
        <meta name="twitter:image" content={globe} />
        <meta name="twitter:card" content={title?.SIGN_UP} />
      </Helmet>
      <div className="flex items-center justify-center min-h-screen">
        <TransitionGroup>
          <CSSTransition key={step} timeout={300} classNames="fade">
            {steps[step].component}
          </CSSTransition>
        </TransitionGroup>
      </div>
      {successModal && (
        <SuccessModal
          show={successModal}
          state={state}
          message={message}
          toggle={handleSuccessModal}
        />
      )}
    </div>
  );
};

export default Signup;

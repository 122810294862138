import axios from "axios";
import * as CONSTANT from "../../Components/constants";
import {
  PROMOTIONS_GET,
  PROMOTIONS_HOMEPAGE_GET,
} from "../actiontypes/actionTypes";
import { spinnerLoaderStop } from "./loaderactions";
import { RequestMethods } from "../../shared/RequestMethods";

export const getPromotions =
  (skip: string, limit: string) => (dispatch: any) => {
    const extoken: string =
      localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

    let config = {
      method: RequestMethods.GET,
      url: `${process.env.REACT_APP_BASE_API}/promotions?skip=${skip}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${extoken}`,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        const list: any = {
          ...response.data,
        };
        const action: PROMOTIONS_ACTION = {
          type: PROMOTIONS_GET,
          PROMOTIONS: list,
        };
        dispatch(action);
        dispatch(spinnerLoaderStop());
      })
      .catch(function (error) {
        dispatch(spinnerLoaderStop());
      });
  };

export const createPromotion = async (
  item: CONSTANT.promotionsCreate
): Promise<{
  order_id: string;
  currency: string;
  amount: number;
  promotionId: string;
}> => {
  const extoken: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

  let config = {
    method: RequestMethods.POST,
    url: `${process.env.REACT_APP_BASE_API}/promotions`,
    headers: {
      Authorization: `Bearer ${extoken}`,
      "Content-Type": "application/json",
    },
    data: item,
  };

  const response = await axios(config);
  const { order_id, currency, amount, promotionId } = response.data;

  return { order_id, currency, amount, promotionId };
};

export const retryPromotionPayment = async (
  item: CONSTANT.retryPromotionPaymentInterface
): Promise<{
  order_id: string;
  currency: string;
  amount: number;
  promotionId: string;
}> => {
  const extoken: string = localStorage.getItem("GTI_data")?.split(" ")[0] ?? "";

  let config = {
    method: RequestMethods.PATCH,
    url: `${process.env.REACT_APP_BASE_API}/promotions/retrypayment`,
    headers: {
      Authorization: `Bearer ${extoken}`,
      "Content-Type": "application/json",
    },
    data: item,
  };

  const response = await axios(config);
  const { order_id, currency, amount, promotionId } = response.data;

  return { order_id, currency, amount, promotionId };
};

export const fetchHomeapgePromotion = () => (dispatch: any) => {
  let config = {
    method: RequestMethods.GET,
    url: `${process.env.REACT_APP_BASE_API}/promotions/homepagepromotions`,
    headers: {
      "Content-Type": "application/json",
    },
  };

  axios(config)
    .then(function (response) {
      const action: PROMOTIONS_ACTION = {
        type: PROMOTIONS_HOMEPAGE_GET,
        PROMOTIONS: {
          homepagePromotions: response?.data?.promotions,
          promotions: [],
          promotionsCount: 0,
        },
      };
      dispatch(action);
      dispatch(spinnerLoaderStop());
    })
    .catch(function (error) {
      dispatch(spinnerLoaderStop());
    });
};

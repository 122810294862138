import React from "react";
import { ImCross } from "react-icons/im";
import { MdArrowRightAlt } from "react-icons/md";

import companyDetails from "../../assests/images/signup/company-details.png";
import { countryList } from "../../shared/constants";

const CompanyDetails = ({
  companyFormik,
  uploadLogo,
  toggle_error,
  comp_logo,
  setUploadLogoM,
  setCompLogo,
}: {
  companyFormik: any;
  uploadLogo: boolean;
  toggle_error: () => void;
  comp_logo: File | null;
  setUploadLogoM: React.Dispatch<React.SetStateAction<boolean>>;
  setCompLogo: React.Dispatch<React.SetStateAction<File | null>>;
}) => {
  const handleLogoUpload = (event: any) => {
    setUploadLogoM(!uploadLogo);
    setCompLogo(event.target.files[0]);
  };

  const handleRemoveCompLogo = () => {
    setCompLogo(null);
    companyFormik.setFieldValue("logo", null);
  };

  const handleCompLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompLogo(e.target.files?.[0] || null);
    companyFormik.setFieldValue("logo", e.target.files![0]);
  };

  return (
    <div className="flex flex-col items-center h-full w-full">
      <div className="flex flex-row relative w-full justify-center md:space-x-4 duration-200 md:mt-10">
        <div className="hidden md:flex flex-col items-center justify-start w-6/12">
          <p className="text-4xl my-5 font-roboto">Company Details</p>
          <div className="flex flex-col w-full h-full max-h-96 items-center">
            <img
              src={companyDetails}
              alt="Company Details"
              className="w-full h-full"
            />
          </div>
        </div>
        <div className="flex flex-col h-full w-9/12 md:w-6/12 items-center">
          <div className="flex justify-center w-full items-center">
            <form
              onSubmit={companyFormik.handleSubmit}
              className="flex flex-col mb-24 w-full"
            >
              <div className="flex flex-col space-y-3">
                <div className="flex flex-col w-full">
                  <div className="relative my-1">
                    <input
                      value={companyFormik.values.name}
                      onChange={companyFormik.handleChange}
                      id="companyName"
                      type="text"
                      name="name"
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                    />
                    <label
                      htmlFor="companyName"
                      id="label_compName"
                      className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Company Name
                    </label>
                  </div>
                  {companyFormik.errors.name && (
                    <p className="text-red-500 text-xs p-2">
                      {companyFormik.errors.name}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <div className="relative my-1">
                    <textarea
                      id="description"
                      value={companyFormik.values.description}
                      onChange={companyFormik.handleChange}
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="description"
                      id="label_compName"
                      className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Company Description
                    </label>
                  </div>
                  {companyFormik.errors.description && (
                    <p className="text-red-500 text-xs p-2">
                      {companyFormik.errors.description}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <div className="relative my-1">
                    <input
                      id="website"
                      type="text"
                      value={companyFormik.values.website}
                      onChange={companyFormik.handleChange}
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=" "
                    />
                    <label
                      htmlFor="website"
                      id="label_compWebsite"
                      className="absolute text-sm text-gray-500 peer-focus:text-blue-600  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Company's Website
                    </label>
                  </div>
                  {companyFormik.errors.website && (
                    <p className="text-red-500 text-xs p-2">
                      {companyFormik.errors.website}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  <select
                    id="country"
                    value={companyFormik.values.country}
                    onChange={companyFormik.handleChange}
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  >
                    <option value="" disabled>
                      Country
                    </option>
                    {countryList.map((country, index) => (
                      <option key={index} value={country.symbol}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {companyFormik.errors.country && (
                    <p className="text-red-500 text-xs p-2">
                      {companyFormik.errors.country}
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-full">
                  {comp_logo ? (
                    <div className="flex flex-row justify-between items-center border border-gray-300 p-3 rounded-md">
                      <p className="font-roboto text-sm text-gray-700 px-4 whitespace-nowrap">
                        Uploaded Logo
                      </p>
                      <button
                        onClick={handleRemoveCompLogo}
                        type="button"
                        className="text-white w-1/2 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex justify-between items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        {comp_logo.name.length > 12
                          ? comp_logo.name.substring(0, 12)
                          : comp_logo.name}
                        <ImCross className="w-5 h-5 p-1" />
                      </button>
                    </div>
                  ) : (
                    <div className="flex items-center justify-center w-full">
                      <label
                        htmlFor="logo"
                        className="flex flex-col items-center justify-center w-full  border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 "
                      >
                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500">
                            <span className="font-semibold">
                              Click to upload Logo
                            </span>
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 ">
                            Company Logo
                            <span className="text-blue-500">(.png)</span>
                          </p>
                        </div>
                        <input
                          id="logo"
                          type="file"
                          className="hidden"
                          accept="image/png"
                          onChange={handleCompLogoChange}
                        />
                      </label>
                    </div>
                  )}
                  {companyFormik.errors.logo && (
                    <p className="text-red-500 text-xs">
                      {companyFormik.errors.logo}
                    </p>
                  )}
                </div>
              </div>
              <button
                type="submit"
                className={` mt-6 flex gap-2 items-center justify-center w-full rounded bg-white hover:bg-GTI-BLUE-default px-5 py-3 border border-GTI-BLUE-default font-medium text-GTI-BLUE-default hover:text-white shadow-md shadow-indigo-500/20`}
              >
                Next
                <MdArrowRightAlt />
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;

// {uploadLogo ? (
//   <div className="z-10 flex absolute duration-200 ease-in-out w-full h-full justify-center bg-slate-700 bg-opacity-30">
//     <input
//       id="upload_logo_file"
//       onChange={(e) => {
//         handleLogoUpload(e);
//       }}
//       type="file"
//       accept="image/*"
//       style={{ display: "none" }}
//     />
//     <label
//       htmlFor="upload_logo_file"
//       className="flex duration-150 -translate-y-20 justify-center items-center w-2/3 "
//     >
//       <img
//         className="w-2/3 h-1/2"
//         src={upload_logo_baner}
//         alt="Upload Logo"
//       />
//     </label>
//   </div>
// ) : null}
// <div id="error-modal" tabIndex={-1} className="hidden" aria-hidden="true">
//   <div className="main -translate-y-1/2 relative  w-full max-w-md h-full md:h-auto">
//     <div className="relative bg-white rounded-lg">
//       <button
//         type="button"
//         onClick={() => {
//           toggle_error();
//         }}
//         className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
//         data-modal-toggle="error-modal"
//       >
//         <svg
//           aria-hidden="true"
//           className="w-5 h-5"
//           fill="currentColor"
//           viewBox="0 0 20 20"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             fillRule="evenodd"
//             d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//             clipRule="evenodd"
//           ></path>
//         </svg>
//         <span className="sr-only">Close modal</span>
//       </button>
//       <div className="p-6 text-center">
//         <svg
//           aria-hidden="true"
//           className="mx-auto mb-4 w-14 h-14 text-gray-400 dark:text-gray-200"
//           fill="none"
//           stroke="currentColor"
//           viewBox="0 0 24 24"
//           xmlns="http://www.w3.org/2000/svg"
//         >
//           <path
//             strokeLinecap="round"
//             strokeLinejoin="round"
//             strokeWidth="2"
//             d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
//           ></path>
//         </svg>
//         <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
//           Some error occurred while submiting your details...
//         </h3>
//         <button
//           data-modal-toggle="error-modal"
//           onClick={() => {
//             toggle_error();
//           }}
//           type="button"
//           className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
//         >
//           OK
//         </button>
//       </div>
//     </div>
//   </div>
// </div>

import axios from "axios";
import { store } from "../../store";
import * as SECTOR from "../../Components/constants";
import { SECTOR_GET, TOAST_SUCCESS } from "../actiontypes/actionTypes";

export const getSector = () => async (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/sectors`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("Sector list fetched ");

      const list: SECTOR.sectorItem[] = response.data;
      const action1: TOAST_ACTION = {
        type: TOAST_SUCCESS,
      };
      const action2: SECTOR_ACTION = {
        type: SECTOR_GET,
        SECTOR: {
          SECTOR_LIST: list,
        },
      };
      // console.log({ list });
      const action = async (dispatch: any) => {
        // dispatch(action1);
        dispatch(action2);
      };

      return dispatch(action2);
    })
    .catch(function (error) {
      // console.log("Sector not list fetched ", error);
    });
};

export const getSectors = async () => {
  const response = await axios.get(`${process.env.REACT_APP_BASE_API}/sectors`);
  const responseData = response.data;
  return responseData;
};

export const getSectorById = (id: string) => async (dispatch: any) => {
  var config = {
    method: "get",
    url: `${process.env.REACT_APP_BASE_API}/sectors/${id}`,
    headers: {
      Authorization: `Bearer ${store.getState().USER.USER.token}`,
      "Content-Type": "application/json",
    },
  };

  await axios(config)
    .then(function (response) {
      // console.log("Sector fetched by id");

      const list: SECTOR.sectorItem[] = [response.data];
      const action1: TOAST_ACTION = {
        type: TOAST_SUCCESS,
      };
      const action2: SECTOR_ACTION = {
        type: SECTOR_GET,
        SECTOR: {
          SECTOR_LIST: list,
        },
      };
      const action = async (dispatch: any) => {
        // dispatch(action1);
        dispatch(action2);
      };

      return dispatch(action);
    })
    .catch(function (error) {
      // console.log("Sector not fetched by id ", error);
    });
};

import React, { Dispatch, useEffect, useRef, useState } from "react";
import { notify } from "../../utils";
import { createUserOppotunity } from "../../store/actioncreators/opportunityactions";
import {
  CONTENT_TYPE,
  CONTENT_TYPE_DOC,
  FILE_PATH,
  FILE_TYPE,
  oppotunityItem,
  presignedData,
  sectorItem,
  subsectorItem,
} from "../constants";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import axios from "axios";
import { RequestMethods } from "../../shared/RequestMethods";
import {
  failToast,
  successToast,
} from "../../store/actioncreators/toastactions";
import { useFormik } from "formik";
import { oppSchema } from "../validations/oppValidations";
import { Editor } from "@tinymce/tinymce-react";

import createProductLogo from "../../assests/images/signup/create_product.svg";
import { getSubSectorBySector } from "../../store/actioncreators/sub-sectoractions";
import { getSectors } from "../../store/actioncreators/sectoractions";

interface MyFormValues {
  name: string;
  tech_require: string;
  description: string;
  sectorId: string;
  subSectorId: string;
}

type files = {
  image: Boolean;
  document: Boolean;
  imageFile: File[];
  documentFiles: File[];
};

const OpportunityDetails = ({
  handleTechnologySubmit,
  handleSuccessModal,
}: {
  handleTechnologySubmit: () => Promise<string>;
  handleSuccessModal: (isOpen: boolean, state: string, message: string) => void;
}) => {
  const [sectors, setSectors] = useState<sectorItem[]>([]);

  const docInputRef = useRef<HTMLInputElement>(null);

  const subsectorlist: SUB_SECTOR = useSelector(
    (state: STATE) => state.SUB_SECTOR.SUB_SECTOR
  );

  const initialValues: MyFormValues = {
    name: "",
    tech_require: "",
    description: "",
    sectorId: sectors.length ? sectors[0]._id : "",
    subSectorId: "",
  };

  const dispatch: Dispatch<any> = useDispatch();

  const handleFetchSectors = async () => {
    const fetchedSectors = await getSectors();
    setSectors(fetchedSectors);
  };

  useEffect(() => {
    handleFetchSectors();
  }, []);

  const [files, setFiles] = useState<files>({
    image: false,
    document: false,
    imageFile: [],
    documentFiles: [],
  });

  const handleImage = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const images = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      images.push(fileList[i]);
    }

    setFiles({ ...files, imageFile: images, image: false });
  };

  const handleDocuments = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files || [];
    if (!fileList) return;

    const documentFiles = [];
    const totalFiles = fileList?.length || 0;
    for (let i = 0; i < totalFiles; i++) {
      documentFiles.push(fileList[i]);
    }

    setFiles({ ...files, documentFiles, document: false });
  };

  const getPresigned = async (content: presignedData) => {
    const data = JSON.stringify(content);
    let result: string = "";
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_BASE_API}/users/getPresignedUrl`,
      headers: {
        "Content-Type": "application/json",
      },
      data,
    };

    await axios(config)
      .then(function (response) {
        result = response.data;
      })
      .catch(function (error) {
        result = "error";
        dispatch(failToast());
      });

    return result;
  };

  const postLogo = async (signed: string, imageFile: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE,
        "Access-Control-Allow-Origin": true,
      },
      data: imageFile,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const postDocument = async (signed: string, file: File) => {
    var config = {
      method: RequestMethods.PUT,
      url: signed,
      headers: {
        "Content-Type": CONTENT_TYPE_DOC,
        "Access-Control-Allow-Origin": true,
      },
      data: file,
    };

    await axios(config)
      .then(function (response) {
        dispatch(successToast());
      })
      .catch(function (error) {});
  };

  const handleCreate = async (values: MyFormValues) => {
    try {
      handleSuccessModal(true, "LOADING", "Please Wait");

      const userId = await handleTechnologySubmit();
      if (!userId) return;

      if (!files.imageFile) {
        return setFiles({ ...files, image: true });
      }
      setFiles({ ...files, document: false, image: false });

      let opportunityImages: string[] = [];
      let documents: string[] = [];

      for (const file of files.imageFile) {
        const signedLogoData: presignedData = {
          fileName: file.name ?? values.name,
          filePath: FILE_PATH.PRODUCTS_IMAGE,
          fileType: FILE_TYPE.PNG,
        };
        let imageUrl = await getPresigned(signedLogoData);
        await postLogo(imageUrl, file);
        opportunityImages.push(imageUrl.split("?")[0]);
      }

      for (const file of files.documentFiles) {
        const signedDocumentData: presignedData = {
          fileName: file.name || values.name,
          filePath: FILE_PATH.COMPANY_DOCS,
          fileType: FILE_TYPE.PDF,
        };
        let documentUrl = await getPresigned(signedDocumentData);
        await postDocument(documentUrl, file);
        documents.push(documentUrl.split("?")[0]);
      }

      const data: oppotunityItem = {
        technologyPartnerRequirement: values.tech_require,
        description: values.description,
        documents,
        images: opportunityImages,
        sectorId: values.sectorId,
        subSectorId: values.subSectorId,
        name: values.name,
      };

      dispatch(createUserOppotunity(data, userId));
      handleSuccessModal(
        true,
        "SUCCESS",
        "An email has been sent on your mail address, please verify it within 24 hours."
      );
    } catch (error) {
      handleSuccessModal(true, "ERROR", "Failed to Register, Please Retry");
      notify("Failed to create opoortunity!", "error");
    }
  };

  const fetchSubsector = async (sectorId: string, currentId: string) => {
    if (currentId !== sectorId || currentId === "")
      dispatch(getSubSectorBySector(sectorId));
  };

  useEffect(() => {
    if (sectors?.length) {
      fetchSubsector(sectors[0]?._id, "");
    }
  }, [sectors]);

  const opportunityformik = useFormik<MyFormValues>({
    initialValues: initialValues,
    validationSchema: oppSchema,
    onSubmit: (values, formikHelpers) => {
      handleCreate(values);
    },
  });

  return (
    <div className="flex items-center justify-center mt-10">
      <div
        className={
          "flex flex-row relative w-3/4 md:w-10/12 justify-center md:space-x-4 duration-200 mb-10"
        }
      >
        <div className="hidden md:flex flex-col items-center justify-start w-6/12 mb-10 ">
          <p className="text-4xl my-5 font-roboto">Opportunity Details</p>
          <p className="text-base p-2 font-roboto border rounded-xl w-11/12">
            Scouter users can upload their specific requirements to find
            innovative solutions or technologies. By posting their needs, they
            gain access to a global pool of innovators and technology providers.
            This helps scouters discover new opportunities, explore potential
            partnerships, and find customized solutions that align with their
            business objectives.
          </p>
          <div className="flex flex-col w-5/6 h-2/3 items-center">
            <img
              src={createProductLogo}
              alt="Company Details"
              className="w-full h-4/5"
            />
          </div>
        </div>
        <div className="flex flex-col h-full md:w-6/12 items-center">
          <div className="flex justify-center w-full items-center">
            <form
              onSubmit={opportunityformik.handleSubmit}
              className="flex flex-col w-full space-y-4 justify-center items-center"
            >
              <div className="flex flex-col w-full space-x-2 relative">
                <div className="flex flex-col w-full space-x-2 relative">
                  <div className="relative mb-4 ">
                    <input
                      type="text"
                      name="name"
                      id="floating_name"
                      onChange={(e) =>
                        opportunityformik.setFieldValue("name", e.target.value)
                      }
                      className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                      placeholder=""
                    />
                    <label
                      htmlFor="floating_name"
                      className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                    >
                      Name
                    </label>
                  </div>
                </div>
                <div className="relative">
                  <textarea
                    onChange={(e) =>
                      opportunityformik.setFieldValue(
                        "tech_require",
                        e.target.value
                      )
                    }
                    id="floating_outlined"
                    className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-transparent rounded-lg border-1 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=""
                  />
                  <label
                    htmlFor="floating_outlined"
                    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-1"
                  >
                    Technology Requirement
                  </label>
                </div>
                {opportunityformik.errors.tech_require && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {opportunityformik.errors.tech_require}
                  </p>
                )}
              </div>
              <div className="flex flex-col w-full space-x-2 relative">
                <div className="relative">
                  <Editor
                    apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
                    init={{
                      height: 200,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                      placeholder: "Write your opportunity description here...",
                    }}
                    initialValue=""
                    onEditorChange={(e) =>
                      opportunityformik.setFieldValue("description", e)
                    }
                  />
                </div>
                {opportunityformik.errors.description && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {opportunityformik.errors.description}
                  </p>
                )}
              </div>

              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full space-x-5 items-center">
                  <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                    Sector Type:
                  </h3>
                  <select
                    onChange={(e) => {
                      fetchSubsector(
                        e.target.value,
                        opportunityformik.values.sectorId
                      );
                      opportunityformik.setFieldValue(
                        "sectorId",
                        e.target.value
                      );
                    }}
                    defaultValue={opportunityformik.values.sectorId}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                  >
                    <option>Select Sector</option>
                    {sectors.map((item: sectorItem, id) => {
                      return (
                        <option key={id} value={item._id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {opportunityformik.errors.sectorId && (
                  <p
                    id="filled_error_help"
                    className="mt-2 text-xs text-red-600 dark:text-red-400"
                  >
                    {opportunityformik.errors.sectorId}
                  </p>
                )}
              </div>
              {subsectorlist.SUB_SECTOR_LIST?.length ? (
                <div className="flex flex-col w-full">
                  <div className="flex flex-row w-full space-x-5 items-center">
                    <h3 className="font-robot text-gray-800 text-sm whitespace-nowrap  ">
                      Sub Sector Type:
                    </h3>
                    <select
                      onChange={(e) =>
                        opportunityformik.setFieldValue(
                          "subSectorId",
                          e.target.value
                        )
                      }
                      defaultValue={initialValues.subSectorId}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
                    >
                      <option>Select Sub Sector</option>
                      {subsectorlist.SUB_SECTOR_LIST.map(
                        (item: subsectorItem, id) => {
                          return (
                            <option key={id} value={item._id}>
                              {item.name}
                            </option>
                          );
                        }
                      )}
                    </select>
                  </div>
                </div>
              ) : null}

              <div className="flex flex-col w-full">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="logo"
                >
                  Opportunity Image&nbsp;
                  <span className="text-red-500 text-xs">(.png only)</span>
                </label>
                <input
                  onChange={handleImage}
                  accept=".png"
                  type="file"
                  id="logo"
                  aria-label="company-logo"
                  className="modal-input"
                  placeholder=""
                  multiple
                  max={3}
                />
                <p
                  id="filled_error_help"
                  className={
                    "mt-2 text-xs text-red-600 dark:text-red-400 " +
                    (!files.image ? "hidden" : "")
                  }
                >
                  {"Please upload opportunity Image"}
                </p>
              </div>

              <div className="flex flex-col w-full relative">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="documents"
                >
                  Opportunity Documents <i>(Optional)</i>&nbsp;
                  <span className="text-red-500 text-xs">(.pdf only)</span>
                </label>
                <input
                  onChange={handleDocuments}
                  accept=".pdf"
                  type="file"
                  id="documents"
                  ref={docInputRef}
                  aria-label="company-documents"
                  className="modal-input"
                  placeholder="Click to upload Document"
                  multiple
                  max={3}
                />
              </div>

              <button
                type="submit"
                className="flex w-full justify-center text-GTI-BLUE-default hover:text-white border border-GTI-BLUE-default  hover:bg-GTI-BLUE-default focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2"
              >
                SUBMIT
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;

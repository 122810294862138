import * as Yup from "yup";
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const personalSignupSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Too short name!")
    .max(50, "Too long name!")
    .required("Full name required"),
  email: Yup.string()
    .email("Valid email required")
    .required("Email address required"),
  password: Yup.string()
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a symbol")
    .required("Password required"),
  country: Yup.string(),
  phone: Yup.string().matches(phoneRegExp, "Phone number is not valid"),
  ref: Yup.string(),
});

export const companySignupSchema = Yup.object().shape({
  name: Yup.string().required("Company name required"),
  description: Yup.string().required("Company description required"),
  // address: Yup.string().required("Company address required"),
  website: Yup.string().required("Company website or LinkedIn id required "),
  country: Yup.string().required("Country required"),
  // turnover: Yup.string(),
  logo: Yup.mixed().required("Company logo is required"),
  // documents: Yup.mixed().required("Company documents are required"),
});
export const companySignupSchema2 = Yup.object().shape({
  comp_id: Yup.string().required("Company id required"),
});
